<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mx-auto">
            <b-link
              class="navbar-brand"
              href="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                />
              </span>
              <!-- <h2 class="brand-text">
                {{ appName }}
              </h2> -->
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
        <!-- User Info-->
        <div class="d-flex justify-content-between align-items-end mt-1">
          <div class="d-flex">
            <b-avatar
              size="40"
              :src="
                userData.profileImage
                  ? `/uploads/profileimage/3/${userData.profileImage}`
                  : require('@/assets/images/avatars/avatar.jpeg')
              "
              variant="light-primary"
              badge
              class="badge-minimal"
              badge-variant="success"
            >
              <feather-icon
                v-if="!userData.userName"
                icon="UserIcon"
                size="22"
              />
            </b-avatar>
            <div class="user-nav ml-75">
              <p class="user-name font-weight-bolder mb-0">
                {{ userData.userName }}
              </p>
              <span class="user-status">{{
                convertGenName(userData.genName)
              }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <b-button
              :to="{ name: 'account-setting' }"
              variant="outline-secondary"
              size="lg"
              class="btn-icon"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
            <b-button
              @click="logout"
              variant="outline-danger"
              size="lg"
              class="btn-icon ml-50"
            >
              <feather-icon icon="LogOutIcon" />
            </b-button>
          </div>
        </div>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{ 'd-block': shallShadowBottom }"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0;
        }
      "
    >
      <!-- 브로셔 다운로드 -->
      <div style="margin: 15px">
        <b-link
          href="http://books.topint.co.kr/uploads/AMPFRI_2023.pdf"
          class="btn btn-block btn-outline-danger"
        >
          <feather-icon icon="DownloadIcon" /> 2023학년도 전기 브로셔 다운로드
        </b-link>
      </div>

      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
  import navMenuItems from '@/navigation/vertical';
  import VuePerfectScrollbar from 'vue-perfect-scrollbar';
  import { BLink, BImg, BAvatar, BButton } from 'bootstrap-vue';
  import { provide, computed, ref } from '@vue/composition-api';
  import useAppConfig from '@core/app-config/useAppConfig';
  import { $themeConfig } from '@themeConfig';
  import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
  import useVerticalNavMenu from './useVerticalNavMenu';

  import { initialAbility } from '@/libs/acl/config';
  import useJwt from '@/auth/jwt/useJwt';

  import FileComponent from '@core/components/file-download/FileComponent.vue';

  export default {
    components: {
      VuePerfectScrollbar,
      VerticalNavMenuItems,
      FileComponent,
      BLink,
      BImg,
      BAvatar,
      BButton,
    },
    props: {
      isVerticalMenuActive: {
        type: Boolean,
        required: true,
      },
      toggleVerticalMenuActive: {
        type: Function,
        required: true,
      },
    },
    setup(props) {
      const {
        isMouseHovered,
        isVerticalMenuCollapsed,
        collapseTogglerIcon,
        toggleCollapsed,
        updateMouseHovered,
      } = useVerticalNavMenu(props);

      const userData = JSON.parse(localStorage.getItem('userData'));

      const { skin } = useAppConfig();

      // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
      const shallShadowBottom = ref(false);

      provide('isMouseHovered', isMouseHovered);

      const perfectScrollbarSettings = {
        maxScrollbarLength: 120,
        wheelPropagation: false,
      };

      const collapseTogglerIconFeather = computed(() =>
        collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'
      );

      // App Name
      const { appName, appLogoImage } = $themeConfig.app;

      return {
        navMenuItems,
        perfectScrollbarSettings,
        isVerticalMenuCollapsed,
        collapseTogglerIcon,
        toggleCollapsed,
        isMouseHovered,
        updateMouseHovered,
        collapseTogglerIconFeather,

        // Shadow Bottom
        shallShadowBottom,

        // Skin
        skin,

        // App Name
        appName,
        appLogoImage,

        userData,
      };
    },
    methods: {
      logout() {
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

        // Remove userData from localStorage
        localStorage.removeItem('userData');

        // Reset ability
        this.$ability.update(initialAbility);

        // Redirect to login page
        this.$router.push({ name: 'auth-login' });
      },
      convertGenName(genName) {
        // console.log(typeof genName, genName);
        return isNaN(genName) ? genName : `${genName}기`;
      },
    },
  };
</script>

<style lang="scss">
  @import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
</style>
