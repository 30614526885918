<template>
  <div style="margin: 15px">
    <b-button
      @click="downloadFile()"
      variant="outline-danger"
      block
      class="btn-icon"
    >
      <feather-icon icon="DownloadIcon" /> 2023학년도 전기 브로셔 다운로드
    </b-button>
  </div>
</template>

<script>
  import axios from 'axios';
  import { BButton } from 'bootstrap-vue';

  export default {
    components: {
      BButton,
    },

    mounted() {
      this.downloadFile();
    },
    methods: {
      downloadFile() {
        axios({
          url: 'http://books.topint.co.kr/uploads/AMPFRI_2023.pdf',
          method: 'GET',
          responseType: 'blob',
        }).then(res => {
          var FILE = window.URL.createObjectURL(new Blob([res.data]));

          var docUrl = document.createElement('a');
          docUrl.href = FILE;
          docUrl.setAttribute(
            'download',
            '제45기 식품 및 외식산업 보건 최고경영자 과정.pdf'
          );
          document.body.appendChild(docUrl);
          docUrl.click();
        });
      },
    },
  };
</script>
