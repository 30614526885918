<template>
  <div class="clearfix mb-0 text-align">
    <b-img
      :src="require('@/assets/images/footer/footer.png')"
      class="mx-auto d-block img-fluid w-100"
      style="max-width: 640px"
    />
  </div>
</template>

<script>
  import { BImg } from 'bootstrap-vue';

  export default {
    components: {
      BImg,
    },
  };
</script>
