/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import dashboard from './dashboard';
// import courseIntroduction from './course-introduction';
// import assosiationIntroduction from './assosiation-introduction';
// import friendsBook from './friends-book';
// import friendsNews from './friends-news';
// import formAndTable from './forms-and-table';

// Array of sections
export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'ACL',
  },
  // *===============================================---*
  // *--------- 과정소개
  // *===============================================---*
  {
    header: 'Course Introduction',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Open Purpose',
    route: { name: 'open-purpose', params: { id: 2 } },
    link: '/menu/2',
    icon: 'InfoIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Course Curriculum',
    route: { name: 'course-curriculum', params: { id: 3 } },
    link: '/menu/3',
    icon: 'LayersIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Organization Chart',
    route: { name: 'organization-chart', params: { id: 4 } },
    link: '/menu/4',
    icon: 'AwardIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Director Chart',
    route: { name: 'director-chart', params: { id: 5 } },
    link: '/pros',
    icon: 'UserCheckIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Committee Member',
    route: { name: 'committee-member', params: { id: 6 } },
    link: '/menu/6',
    icon: 'BookOpenIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Committee Summary',
    route: { name: 'committee-summary', params: { id: 117 } },
    link: '/menu/117',
    icon: 'BookmarkIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Admission Info',
    route: { name: 'admission-info', params: { id: 118 } },
    link: '/menu/118',
    icon: 'CheckSquareIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Location Map',
    route: { name: 'location-map', params: { id: 7 } },
    link: '/menu/7',
    icon: 'MapIcon',
    action: 'read',
    resource: 'ACL',
  },
  // *===============================================---*
  // *--------- 총동문회 소개
  // *===============================================---*
  {
    header: 'Association Introduction',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Association Rule', // 동문회 회칙
    route: { name: 'association-rule', params: { id: 12 } },
    link: '/menu/12',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Chairman Greeting', // 총동문회장 인사말
    route: { name: 'chairman-greeting', params: { id: 10 } },
    link: '/menu/10',
    icon: 'SmileIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Presidency List', // 역대 회장단
    route: { name: 'presidency-list', params: { id: 8 } },
    link: '/menu/8',
    icon: 'UserCheckIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Association Chart', //26대 임원진 명단
    route: { name: 'association-chart', params: { id: 9 } },
    link: '/menu/9',
    icon: 'GitPullRequestIcon',
    action: 'read',
    resource: 'ACL',
  },

  // {
  //   title: 'Association Presidency',
  //   route: { name: 'association-presidency', params: { id: 10 } },
  //   link: '/menu/10',
  //   icon: 'CheckSquareIcon',
  //   action: 'read',
  //   resource: 'ACL',
  // },
  // {
  //   title: 'Presidency of Gens',
  //   route: { name: 'gens-presidency', params: { id: 14 } },
  //   link: '/post/list/14',
  //   icon: 'UserCheckIcon',
  //   action: 'read',
  //   resource: 'ACL',
  // },

  // *===============================================---*
  // *--------- 행사소식
  // *===============================================---*
  {
    header: 'Event Notice',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Event Schedule', // 행사일정
    route: { name: 'event-schedule', params: { id: 14 } },
    link: '/post/list/14',
    icon: 'CalendarIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Golf Meeting', // 골프회
    route: { name: 'golf-meeting', params: { id: 15 } },
    link: '/post/list/15',
    icon: 'TargetIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Climbing Meeting', // 등산회
    route: { name: 'climbing-meeting', params: { id: 23 } },
    link: '/post/list/23',
    icon: 'FlagIcon',
    action: 'read',
    resource: 'ACL',
  },
  // *===============================================---*
  // *--------- 동문수첩
  // *===============================================---*
  {
    header: 'Friends',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Friends Book',
    route: 'friends-book',
    link: '/user/books',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'ACL',
  },
  // *===============================================---*
  // *--------- 동문소식
  // *===============================================---*
  {
    header: 'Firends Notification',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Announcement',
    route: { name: 'announcement', params: { id: 18 } },
    link: '/post/list/18',
    icon: 'BellIcon',
    action: 'read',
    resource: 'ACL',
  },
  {
    title: 'Friends News',
    route: { name: 'friends-news', params: { id: 19 } },
    link: '/post/list/19',
    icon: 'MessageCircleIcon',
    action: 'read',
    resource: 'ACL',
  },
  // {
  //   title: 'Free Board',
  //   route: { name: 'freeboard', params: { id: 20 } },
  //   link: '/post/list/20',
  //   icon: 'ClipboardIcon',
  //   action: 'read',
  //   resource: 'ACL',
  // },
  {
    title: 'Event Gallery',
    route: { name: 'event-gallery', params: { id: 21 } },
    link: '/post/list/21',
    icon: 'ImageIcon',
    action: 'read',
    resource: 'ACL',
  },
  // {
  //   title: 'Event Schedule',
  //   route: { name: 'event-schedule' },
  //   icon: 'CalendarIcon',
  // },
  // *===============================================---*
  // *--------- 시스템 설정
  // *===============================================---*
  {
    header: 'System Settings',
  },
  {
    title: 'Banner Management',
    route: { name: 'banner-manage' },
    icon: 'ExternalLinkIcon',
  },
];
