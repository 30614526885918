var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow",class:[
    {
      expanded:
        !_vm.isVerticalMenuCollapsed ||
        (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
    },
    _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded"},[_vm._t("header",[_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mx-auto"},[_c('b-link',{staticClass:"navbar-brand",attrs:{"href":"/"}},[_c('span',{staticClass:"brand-logo"},[_c('b-img',{attrs:{"src":_vm.appLogoImage,"alt":"logo"}})],1)])],1),_c('li',{staticClass:"nav-item nav-toggle"},[_c('b-link',{staticClass:"nav-link modern-nav-toggle"},[_c('feather-icon',{staticClass:"d-block d-xl-none",attrs:{"icon":"XIcon","size":"20"},on:{"click":_vm.toggleVerticalMenuActive}}),_c('feather-icon',{staticClass:"d-none d-xl-block collapse-toggle-icon",attrs:{"icon":_vm.collapseTogglerIconFeather,"size":"20"},on:{"click":_vm.toggleCollapsed}})],1)],1)]),_c('div',{staticClass:"d-flex justify-content-between align-items-end mt-1"},[_c('div',{staticClass:"d-flex"},[_c('b-avatar',{staticClass:"badge-minimal",attrs:{"size":"40","src":_vm.userData.profileImage
                ? ("/uploads/profileimage/3/" + (_vm.userData.profileImage))
                : require('@/assets/images/avatars/avatar.jpeg'),"variant":"light-primary","badge":"","badge-variant":"success"}},[(!_vm.userData.userName)?_c('feather-icon',{attrs:{"icon":"UserIcon","size":"22"}}):_vm._e()],1),_c('div',{staticClass:"user-nav ml-75"},[_c('p',{staticClass:"user-name font-weight-bolder mb-0"},[_vm._v(" "+_vm._s(_vm.userData.userName)+" ")]),_c('span',{staticClass:"user-status"},[_vm._v(_vm._s(_vm.convertGenName(_vm.userData.genName)))])])],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{staticClass:"btn-icon",attrs:{"to":{ name: 'account-setting' },"variant":"outline-secondary","size":"lg"}},[_c('feather-icon',{attrs:{"icon":"SettingsIcon"}})],1),_c('b-button',{staticClass:"btn-icon ml-50",attrs:{"variant":"outline-danger","size":"lg"},on:{"click":_vm.logout}},[_c('feather-icon',{attrs:{"icon":"LogOutIcon"}})],1)],1)])],{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
        _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
      }}},[_c('div',{staticStyle:{"margin":"15px"}},[_c('b-link',{staticClass:"btn btn-block btn-outline-danger",attrs:{"href":"http://books.topint.co.kr/uploads/AMPFRI_2023.pdf"}},[_c('feather-icon',{attrs:{"icon":"DownloadIcon"}}),_vm._v(" 2023학년도 전기 브로셔 다운로드 ")],1)],1),_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main",attrs:{"items":_vm.navMenuItems}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }